const MISSION_CONTROL = {
    VISIT_LOG: (patientId: number) =>
        `/command-center/v1/mission-control/visit-log/${patientId}/log-patient-visit` as const,
    USERS_LOG: '/command-center/v1/mission-control/visit-log',
    PATIENT_BY_NAME: '/command-center/v1/mission-control/patients/by-name',
    PERSONNEL_BY_NAME: '/command-center/v1/mission-control/personnel/byName',
    PATIENTS: '/command-center/v1/mission-control/patients',
    PATIENTS_BASIC: '/command-center/v1/mission-control/patients/basic-data',
    PATIENT_BASIC: (id: number) => `/command-center/v1/mission-control/patients/${id}/basic-data`,
    PATIENT_SCRATCHPAD: '/command-center/v1/mission-control/patient-scratchpad',
    PATIENT_SCRATCHPAD_BY_ID: (scratchPadId: number) =>
        `/command-center/v1/mission-control/patient-scratchpad/${scratchPadId}` as const,
    PATIENT_SCRATCHPADS_BY_ID: (patientId: number) =>
        `/command-center/v1/mission-control/patient-scratchpad/by-patient/${patientId}/all` as const,
    PATIENT_LAST_SCRATCHPAD_BY_ID: (patientId: number) =>
        `/command-center/v1/mission-control/patient-scratchpad/by-patient/${patientId}/last` as const,
    PATIENT_PRECAUTIONS: '/command-center/v1/patient-precautions',
    PATIENT_PRECAUTION: (precautionId: number) => `/command-center/v1/patient-precautions/${precautionId}` as const,
    DISCONTINUE_PATIENT_PRECAUTION: (precautionId: number) =>
        `/command-center/v1/patient-precautions/${precautionId}/discontinue` as const,
    VOID_PATIENT_PRECAUTION: (precautionId: number) =>
        `/command-center/v1/patient-precautions/${precautionId}/void` as const,
    PATIENT_PRECAUTIONS_HISTORY: '/command-center/v1/patient-precautions/history',
    HAS_ACTIVE_PATIENT_PRECAUTION: (patientId: number) =>
        `/command-center/v1/patient-precautions/${patientId}/has-active` as const,
    VOID_PATIENT: (patientId: number) => `/command-center/v1/mission-control/patients/${patientId}/void` as const,
    DISCHARGE_PATIENT: (patientId: number) =>
        `/command-center/v2/mission-control/patients/${patientId}/discharge` as const,
    UNDISCHARGE_PATIENT: (patientId: number) =>
        `/command-center/v1/mission-control/patients/${patientId}/undischarge` as const,
    ACTIVATE_PATIENT: (id: number) => `/command-center/v2/mission-control/patients/${id}/activate` as const,
    DEACTIVATE_PATIENT: (id: number) => `/command-center/v1/mission-control/patients/${id}/deactivate` as const,
    DEACTIVATION_INFO: (id: number) => `/command-center/v1/mission-control/patients/${id}/deactivatation-info` as const,
    DISCHARGE_DESTINATIONS: '/command-center/v1/mission-control/discharge-destination',
    DEMO_PATIENTS: '/command-center/v1/mission-control/patients/demo-patient',
    ASSIGMENT_CHECK: (patientId: number) =>
        `/command-center/v1/mission-control/patients/${patientId}/assignment-check` as const,
    PERSONNEL_NURSES: '/command-center/v2/personnel/nurses',
    PATIENT_ASSIGNABLE: '/command-center/v2/personnel/patient-assignable',
    PERSONNEL_ROLES: '/command-center/v1/mission-control/personnel/roles',
    ACTIVE_PERSONNEL: '/command-center/v2/personnel/active',
    ARCHIVED_PERSONNEL: '/command-center/v2/personnel/archived',
    REGISTERED_NURSE: (id: number) => `/command-center/v1/mission-control/personnel/${id}` as const,
    TURN_ON_LICENSE_STATES: (id: number) => `/command-center/v1/personnel/${id}/turn-on-license-states` as const,
    TURN_OFF_LICENSE_STATES: (id: number) => `/command-center/v1/personnel/${id}/turn-off-license-states` as const,
    ADD_STATE: (id: number) => `/command-center/v1/personnel/${id}/add-license-state` as const,
    ASSIGN_TO_CLUSTER: (id: number) => `/command-center/v1/personnel/${id}/assign-to-cluster` as const,
    REMOVE_FROM_CLUSTER: (id: number) => `/command-center/v1/personnel/${id}/remove-from-cluster` as const,
    UPDATE_SPONSOR_LIMITATION: (id: number) => `/command-center/v1/personnel/${id}/update-sponsor-limitation` as const,
    REMOVE_STATE: (id: number) => `/command-center/v1/personnel/${id}/remove-license-state` as const,
    UPDATE_ROUTING_PROFILE: (id: number) => `/command-center/v2/personnel/${id}/routing-profile` as const,
    UPDATE_ROLES: (id: number) => `/command-center/v2/personnel/${id}/roles` as const,
    UPDATE_GENERAL_INFO: (id: number) => `/command-center/v2/personnel/${id}/general-info` as const,
    GET_PERSONNEL_BY_ROLE: (roles: string[]) => `/command-center/v2/personnel/role/${roles}` as const,
    GET_PERSONNEL_FULL_MODEL_BY_ROLE: (roles: string[]) => `/command-center/v2/personnel/role/${roles}/full` as const,
    REGISTERED_BULK_NURSES: '/command-center/v1/mission-control/personnel/upload',
    BULK_TEMPLATE: '/command-center/v1/mission-control/personnel/template',
    EXPORT_REGISTERED_NURSES: '/command-center/v1/mission-control/personnel/export-personnel',
    ARCHIVE_PERSONNEL: (id: number) => `/command-center/v2/personnel/${id}/archive` as const,
    RESTORE_PERSONNEL: (id: number) => `/command-center/v2/personnel/${id}/restore` as const,
    SHIFT_ASSIGMENTS: '/command-center/v1/mission-control/shift-assignments',
    SHIFT_ASSIGMENTS_MULTIPLE: '/command-center/v1/mission-control/shift-assignments/multiple',
    VIDEO: '/command-center/v1/mission-control/video/meeting',
    VIDEO_ACTIVE: (patientId: number) =>
        `/command-center/v1/mission-control/video/meeting/${patientId}/active` as const,
    ISO_LANGUAGES: '/command-center/v1/mission-control/languages',
    GENDERS: '/command-center/v1/mission-control/patients/genders',
    PHASE_OF_CARE: '/command-center/v1/mission-control/patients/phase-of-care',
    NURSE_OPTIONS: '/command-center/v1/product-configuration/show-nurse-option',
    GENDER_IDENTITIES: '/command-center/v1/mission-control/patients/gender-identities',
    SPONSORS: (filterUnavailable = false) =>
        `/command-center/v1/mission-control/patients/sponsors${
            filterUnavailable === true ? '?filterUnavailable=true' : ''
        }` as const,
    SPONSORS_DETAILED_LIST: '/command-center/v1/sponsors',
    PAYORS: (filterUnavailable = false) =>
        `/command-center/v1/mission-control/patients/payors${
            filterUnavailable === true ? '?filterUnavailable=true' : ''
        }`,
    PRODUCTS: (filterUnavailable = false) =>
        `/command-center/v1/mission-control/patients/products${
            filterUnavailable === true ? '?filterUnavailable=true' : ''
        }`,
    RELATIONS: '/command-center/v1/mission-control/patients/additional-contacts/relation-options',
    LOCAL_911_TYPES: '/command-center/v1/mission-control/patients/local911-types',
    ADDITIONAL_LOCAL_EMERGENCY_TYPES: '/command-center/v1/mission-control/patients/emergency-types',
    EHRS: '/command-center/v1/mission-control/patients/ehrs',
    SEXUAL_ORIENTATIONS: '/command-center/v1/mission-control/patients/sexual-orientations',
    RACES: '/command-center/v1/mission-control/patients/races',
    ETHNICITIES: '/command-center/v1/mission-control/patients/ethnicities',
    TIME_ZONES: '/command-center/v1/mission-control/timezones',
    PRECAUTIONS: '/command-center/v1/precautions',
    CREATE_PERSONNEL: '/command-center/v2/personnel',
    GEOGRAPHY_STATES: '/command-center/v1/mission-control/geography/states',
    //MISSION_CONTROLS: '/command-center/v1/mission-control/mission-controls',
    MISSION_CONTROLS: '/command-center/v1/mission-control/personnel/self/mission-controls',
    GEOGRAPHY_CITIES: '/command-center/v1/mission-control/geography/cities',
    LOCATIONS_CITIES: '/command-center/v1/mission-control/custom-geography/town',
    LOCATIONS_NEIGHBORHOODS: '/command-center/v1/mission-control/custom-geography/neighborhood',
    LOCATIONS_CITY: (id: number) => `/command-center/v1/mission-control/custom-geography/town/${id}` as const,
    LOCATIONS_NEIGHBORHOOD: (id: number) =>
        `/command-center/v1/mission-control/custom-geography/neighborhood/${id}` as const,
    CALLS_HISTORY: (patientId: number) => `/command-center/v1/mission-control/call-history/${patientId}` as const,
    CLUSTERS: '/command-center/v1/clusters',
    CLUSTERS_OVERVIEW: '/command-center/v1/clusters/overview',
    CLUSTER: (id: number) => `/command-center/v1/clusters/${id}` as const,
    ADD_CLUSTER_STATE: (id: number) => `/command-center/v1/clusters/${id}/add-state` as const,
    REMOVE_CLUSTER_STATE: (id: number) => `/command-center/v1/clusters/${id}/remove-state` as const,
    ADD_CLUSTER_ESCALATIONS: (id: number) => `/command-center/v1/clusters/${id}/add-escalations` as const,
    REMOVE_CLUSTER_ESCALATIONS: (id: number) => `/command-center/v1/clusters/${id}/remove-escalations` as const,
    TURN_ON_CLUSTER_STATES_SPECIFIED: (id: number) =>
        `/command-center/v1/clusters/${id}/turn-on-states-specified` as const,
    TURN_OFF_CLUSTER_STATES_SPECIFIED: (id: number) =>
        `/command-center/v1/clusters/${id}/turn-off-states-specified` as const,
    DEPRECATED_CLUSTERS: '/command-center/v1/mission-control/clusters',
    CLUSTER_PODS: (clusterId: number) => `/command-center/v1/mission-control/cluster/${clusterId}/pods` as const,
    PODS: '/command-center/v1/pods',
    POD: (podId: number) => `/command-center/v1/mission-control/pods/${podId}` as const,
    POD_ACTIVATE: (id: number) => `/command-center/v1/pods/${id}/activate` as const,
    CHANGE_CALL_ROUTING: (id: number) => `/command-center/v1/clusters/${id}/handle-unknown-caller-queue` as const,
    POD_DEACTIVATE: (id: number) => `/command-center/v1/pods/${id}/deactivate` as const,
    UPDATE_CALL_CONTACT_ATTR: '/telephony/v1/call-center/transfer-attributes',
    SEND_CALL_ATTRIBUTES: '/telephony/v1/call-center/call-attributes',
    PHONE_WHITE_LIST: '/telephony/v1/phone-routing/direct-numbers',
    UPDATE_PHONE_WHITE_LIST: (phone: string) => `/telephony/v1/phone-routing/direct-numbers/${phone}` as const,
    PHONE_BLACK_LIST: '/telephony/v1/phone-routing/blocked-numbers',
    UPDATE_PHONE_BLACK_LIST: (phone: string) => `/telephony/v1/phone-routing/blocked-numbers/${phone}` as const,
    CURRENT_USER: '/command-center/v1/mission-control/personnel/self',
    MISSION_CONTROL_CONFIGURATION: (missionControlId: number) =>
        `/command-center/v1/product-configuration/${missionControlId}` as const,
    TIMEZONE_BY_ZIP_CODE: '/command-center/v1/mission-control/timezones/by-zip',
    UPDATE_CONFIGURATION: (config: string, value: boolean) =>
        `/command-center/v1/configuration/${config}/${value}` as const,
    REFERRING_FACILITIES_OVERVIEW: '/command-center/v1/referring-facility/overview',
    REFERRING_FACILITIES: '/command-center/v1/referring-facility',
    ARCHIVE_REFERRING_FACILITY: (id: number) => `/command-center/v1/referring-facility/${id}/archive` as const,
    RESTORE_REFERRING_FACILITY: (id: number) => `/command-center/v1/referring-facility/${id}/restore` as const,
    SERVICE_COORDINATORS: '/command-center/v2/personnel/service-coordinators',
    PATIENT_ACTIVE_MEETING: (nurseId: number) => `/command-center/v1/mission-control/missed-calls/${nurseId}` as const,
    MARK_SEEN_CALLS: '/command-center/v1/mission-control/missed-calls/mark-seen' as const,
    VOIDED_REASONS: '/command-center/v1/mission-control/patients/voided-reasons',
    MISSED_CALLS_UNVIEWED_COUNT: '/command-center/v1/mission-control/missed-calls/unviewed-count' as const,
    VALIDATE_ASSIGN_TO_CLUSTER: '/command-center/v1/personnel/validate-assign-to-cluster',
    BULK_ASSIGN_TO_CLUSTER: '/command-center/v1/personnel/assign-to-cluster',
    BUILD_INFO: '/command-center/public/v1/build-info',
} as const;

const INVENTORY = {
    EQUIPMENT_KITS: '/command-center/v1/inventory/equipment-kits',
    EQUIPMENT_KIT: (kitId: number) => `/command-center/v1/inventory/equipment-kits/${kitId}` as const,
    EQUIPMENT_KIT_DEVICES: (kitId: number) => `/command-center/v1/inventory/equipment-kits/${kitId}/devices` as const,
    EQUIPMENT_KIT_LOCATIONS: '/command-center/v1/inventory/equipment-depots',
    EQUIPMENT_NON_KIT_DEVICES_SPECIFICATIONS: '/command-center/v1/inventory/non-kit-device-specifications',
    EQUIPMENT_KIT_SPECIFICATIONS: '/command-center/v1/inventory/equipment-kit-specifications',
    EQUIPMENT_KIT_STATUSES: '/command-center/v1/inventory/equipment-kits/statuses',
    EQUIPMENT_KIT_NAME_EXISTS: '/command-center/v1/inventory/equipment-kits/name-exists',

    EQUIPMENT_KIT_POST_INSTALLATION_CHECKLIST_FULL_SIZE_IMAGE: (documentId: string) =>
        `/command-center/v1/inventory/patients/visual-documentation/${documentId}` as const,
    EQUIPMENT_KIT_FIELDS_HISTORY: (kitId: number) =>
        `/command-center/v1/inventory/equipment-kits/${kitId}/history` as const,
    EQUIPMENT_KIT_STATUS_HISTORY: (kitId: number) =>
        `/command-center/v1/inventory/equipment-kits/${kitId}/status-updates` as const,
    EQUIPMENT_KIT_DEVICE_UPDATE_HISTORY: (kitId: number) =>
        `/command-center/v1/inventory/equipment-kits/${kitId}/device-updates` as const,

    DEVICES: '/command-center/v1/inventory/devices',
    DEVICE_STATUSES: '/command-center/v1/inventory/devices/statuses',
    DEVICE_MODELS: '/command-center/v1/inventory/device-models',
    DEVICE_FIELDS: '/command-center/v1/inventory/device-models/device-type-specific-fields',
    DEVICES_NEW_QR: '/command-center/v1/inventory/tablet_registrations/new',
    DEVICE_SINGLE: (id: number) => `/command-center/v1/inventory/devices/${id}` as const,
    VALIDATE_YEALINK_DEVICE: '/command-center/v1/inventory/devices/validate-yealink',
    PATIENT_INVENTORY: (id: number) => `/command-center/v1/inventory/patients/${id}/equipment-kits` as const,
    ASSIGN_DEVICES_TO_KIT: (id: number) => `/command-center/v1/inventory/equipment-kits/${id}/devices` as const,
    KIT_ASSIGNMENT_STATUSES: '/command-center/v1/inventory/devices/kit-assignment-statuses',
    POST_INSTALLATION_CHECKLIST: (id: number) =>
        `/command-center/v1/inventory/patients/installation-checklist/${id}` as const,
    GET_WIFI_INFORMATION: (patientId: number) => `/command-center/v1/inventory/wifi?patientId=${patientId}` as const,
    GET_WIFI_STATUS: (patientId: number) => `/command-center/v1/inventory/wifi/status?patientId=${patientId}` as const,
    NON_KIT_DEVICES: (patientId: number) =>
        `/command-center/v1/inventory/patients/${patientId}/non-kit-devices` as const,
    CALCULATE_CYLINDER_DURATION:
        '/command-center/v1/inventory/patients/non-kit-devices/draft-cylinder-duration' as const,
    SAVE_PATIENT_CYLINDER_DURATION: (patientId: number) =>
        `/command-center/v1/inventory/patients/${patientId}/non-kit-devices/cylinder-duration` as const,
    ANELTO_BATTERY_STATUS: (serialNumber: string) =>
        `/command-center/v1/inventory/anelto/last-battery-statuses/${serialNumber}`,
} as const;

const CARE_PLANNING = {
    ORDERS: '/command-center/v1/care-planning/orders',
    ORDERS_WITHOUT_ACTIVITIES: '/command-center/v1/care-planning/orders/no-activities',
    ORDER: (id: number) => `/command-center/v1/care-planning/orders/${id}` as const,
    ORDER_HISTORY: (orderId: number) => `/command-center/v1/care-planning/orders/${orderId}/history` as const,
    ORDER_FREQUENCIES: '/command-center/v2/care-planning/orders/frequencies',
    ORDER_ORDER_NAMES: '/command-center/v1/care-planning/orders/order-specifications',
    ORDER_SPECIFICATION_MODEL: (patientId: number) =>
        `/command-center/v2/care-planning/orders/patient/${patientId}/order-specification-model` as const,
    ORDER_ORDER_TYPES: '/command-center/v1/care-planning/orders/order-types',
    ORDER_CATEGORIES: (patientId: string) =>
        `/command-center/v2/care-planning/orders/patient/${patientId}/order-categories` as const,
    ORDER_ACTIVITY_BUNDLES: '/command-center/v1/care-planning/orders/clinical-questions',
    ORDER_ACTIVITY_SPECIFICATIONS: (orderId: number) =>
        `/command-center/v1/care-planning/orders/${orderId}/activity-specifications` as const,
    ORDER_FLEXIBLE_ORDER_ACTIVITIES: (orderId: number) =>
        `/command-center/v1/care-planning/orders/${orderId}/flexible-order-activities` as const,
    ORDER_ADD_PRN_ACTIVITIES: (orderId: number) =>
        `/command-center/v1/care-planning/orders/${orderId}/plan-prn-activities` as const,
    ORDER_SUBCATEGORIES: '/command-center/v2/care-planning/orders/sub-categories',
    ORDER_ATTRIBUTES: '/command-center/v2/care-planning/orders/attributes',
    ORDER_ATTRIBUTES_BY_ID: (id: number) => `/command-center/v1/care-planning/orders/${id}/activities` as const,
    ACTIVE_IN_HOME_ORDERS: '/command-center/v1/care-planning/orders/active-in-home-orders',
    ACKNOWLEDGE_CHANGES: (id: number) => `/command-center/v1/care-planning/orders/${id}/change-acknowledge` as const,
    ACKNOWLEDGE_LIST: '/command-center/v1/care-planning/orders/acknowledge-list',
    ORDER_CHANGES_BY_ID: (id: number) => `/command-center/v1/care-planning/orders/${id}/changes` as const,
    ORDER_IGNORE_REASONS: '/command-center/v1/care-planning/orders/ignore-reason-options',
    ORDER_RESTORE_IGNORED: (id: number) => `/command-center/v1/care-planning/orders/${id}/restore-ignored` as const,
    ORDER_IGNORE: (id: number) => `/command-center/v1/care-planning/orders/${id}/ignore` as const,
    APPOINTMENT_GENERAL: '/command-center/v1/care-planning/appointments/general',
    APPOINTMENT_AUTOSAVE: '/command-center/v1/care-planning/appointments/in-home/scheduling-window',
    APPOINTMENT_GENERAL_ATTENDEE: '/command-center/v1/care-planning/appointments/general/attendees',
    GENERAL_APPOINTMENTS_TYPES: '/command-center/v1/care-planning/appointments/general/types',
    APPOINTMENT_GENERAL_OVERVIEW: (id: number) =>
        `/command-center/v1/care-planning/appointments/general/${id}/overview` as const,
    APPOINTMENT_IN_HOME_OVERVIEW: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/overview` as const,
    APPOINTMENT_GENERAL_DELETE: (id: number) => `/command-center/v1/care-planning/appointments/general/${id}` as const,
    GENERAL_APPOINTMENT_NOTE: (id: number) =>
        `/command-center/v1/care-planning/appointments/general/${id}/note` as const,
    APPOINTMENTS_IN_HOME: '/command-center/v1/care-planning/appointments/in-home',
    APPOINTMENTS_IN_HOME_DELETE: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/remove` as const,
    ADD_ACTIVITIES_FROM_SERVICE_REQUEST: '/command-center/v1/care-planning/appointments/in-home/add-activities-from-sr',
    APPOINTMENT_IN_HOME: (id: number) => `/command-center/v1/care-planning/appointments/in-home/${id}` as const,
    APPOINTMENT_MISSED_IN_HOME: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/update-missed-check-in-out` as const,
    APPOINTMENT_IN_HOME_CALENDAR_OVERVIEW: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/calendar-details` as const,
    APPOINTMENT_CANCEL: (id: number) => `/command-center/v1/care-planning/appointments/in-home/${id}/cancel` as const,
    APPOINTMENT_UNCANCEL: (id: number) =>
        `/command-center/v2/care-planning/appointments/in-home/${id}/uncancel` as const,
    APPOINTMENT_UNCANCEL_REASON_OPTIONS:
        '/command-center/v1/care-planning/appointments/in-home/uncancellation-reason-options',
    APPOINTMENT_UNDO_CHECK_IN: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/undo-check-in` as const,
    APPOINTMENT_UNDO_CHECK_OUT: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/undo-check-out` as const,
    APPOINTMENT_UNDO_CONFIRM: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/undo-confirm` as const,
    APPOINTMENT_IN_HOME_MULTIPLE: '/command-center/v1/care-planning/appointments/in-home/multi-day',
    APPOINTMENT_IN_HOME_CONFIRM: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/confirm` as const,
    APPOINTMENT_IN_HOME_DOCUMENT: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/document` as const,
    APPOINTMENTS_UNPLANNED: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/unplanned-activities` as const,
    APPOINTMENTS_MISSED_CHECK_IN_CHECK_OUT: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/report-missed-check-in-out` as const,
    APPOINTMENTS_CANCEL_REASONS: '/command-center/v1/care-planning/appointments/in-home/cancellation-reason-options',
    APPOINTMENT_CHECK_OUT: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/check-out` as const,
    APPOINTMENTS: '/command-center/v1/care-planning/appointments',
    APPOINTMENTS_BY_DATE: '/command-center/v1/care-planning/appointments/patient-schedule',
    APPOINTMENTS_TYPES: '/command-center/v1/care-planning/appointments/types',
    APPOINTMENTS_MINI_NOTES: '/command-center/v1/care-planning/appointments/mini-notes',
    APPOINTMENTS_HISTORY: (id: number) => `/command-center/v1/care-planning/appointments/${id}/history` as const,
    APPOINTMENT_NOTE: (id: number) => `/command-center/v1/care-planning/appointments/in-home/${id}/note` as const,
    ORDER_ACKNOWLEDGE: (id: number) => `/command-center/v1/care-planning/orders/${id}/acknowledge` as const,
    ORDER_CANCEL: (id: number) => `/command-center/v1/care-planning/orders/${id}/cancel-initiate` as const,
    ORDER_CANCEL_ACKNOWLEDGE: (id: number) =>
        `/command-center/v1/care-planning/orders/${id}/cancel-acknowledge` as const,
    ORDER_RELATED: (id: number) => `/command-center/v1/care-planning/orders/${id}/related` as const,
    ORDER_UNPLANNED_ACTIVITIES: (id: number) =>
        `/command-center/v2/care-planning/appointments/in-home/${id}/add-activities` as const,
    ORDER_RELATED_MULTIPLE: (id: number) => `/command-center/v1/care-planning/orders/${id}/multi-related` as const,
    ORDER_ACTIVITY_LABELS: (id: number) => `/command-center/v1/care-planning/orders/${id}/labels` as const,
    APPOINTMENT_CHECK_IN_GET_CHECKLIST: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/check-in` as const,
    APPOINTMENT_CHECK_IN_CONFIRM: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/check-in` as const,
    APPOINTMENT_CHANGE_VENDOR: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/change-vendor` as const,
    APPOINTMENT_mark_unavailable_loading: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/cancel-by-provider-unavailability` as const,
    ACTOR_TYPES: '/command-center/v1/care-planning/actor-types',
    ACTOR_ACTIVITIES_GET: (actorCode: string) =>
        `/command-center/v1/care-planning/order-specifications/actor/${actorCode}/default-activities` as const,
    EHR_MESSAGE_HISTORY_GET: '/command-center/v1/care-planning/hl7-messages',
    MESSAGE_TYPES: '/command-center/v1/care-planning/hl7-messages/message-type',
    HAND_OFF: '/command-center/v2/care-planning/hand-off',
    HAND_OFF_SUITABLE: (handOffId: number) => `/command-center/v3/hand-offs/${handOffId}/appointments` as const,
    CREATE_HAND_OFF: '/command-center/v1/care-planning/hand-off',
    VENDOR_SUBSTATUSES: '/command-center/v1/hand-off/vendors/substatuses',
    CHANGE_VENDOR_STATUS: (vendorId: number) => `/command-center/v1/hand-off/vendors/${vendorId}` as const,
    CHANGE_VENDOR_REFERRAL_ACCEPTED_DATE: (handOffId: number) =>
        `/command-center/v1/hand-off/vendors/${handOffId}/set-referral-accepted-date` as const,
    CHANGE_VENDOR_REFERRAL_SENT_DATE: (handOffId: number) =>
        `/command-center/v1/hand-off/vendors/${handOffId}/set-referral-sent-date` as const,
    CHANGE_VENDOR_REFERRAL_DATES: (handOffId: number) =>
        `/command-center/v1/hand-off/vendors/${handOffId}/referral-dates` as const,
    CHANGE_APPOINTMENT_REFERRAL_DATES: (id: number) =>
        `/command-center/v1/appointments/in-home/referrals/${id}` as const,
    HAND_OFF_CANCELLATIONS: '/command-center/v2/care-planning/cancellation-service-requests',
    HAND_OFF_CANCELLATIONS_CONFIRM: (serviceRequestId: number) =>
        `/command-center/v1/care-planning/cancellation-service-requests/${serviceRequestId}/confirm-cancellation` as const,
    HAND_OFF_CANCELLATION_DETAIL: (handOffId: number) =>
        `/command-center/v1/care-planning/cancellation-service-requests/${handOffId}` as const,
    HAND_OFF_BY_ID: (id: number) => `/command-center/v2/care-planning/hand-off/${id}` as const,
    HAND_OFF_OVERVIEW_BY_ID: (id: number) => `/command-center/v2/care-planning/hand-off/${id}/overview` as const,
    HAND_OFF_CANCELLATION_OVERVIEW_BY_ID: (id: number) =>
        `/command-center/v2/care-planning/cancellation-service-requests/${id}/overview` as const,
    HAND_OFF_MOVE_TO_NOT_AVAILABLE: ({
        handOffId,
        vendorCode,
        siteCode,
        actorCode,
    }: {
        handOffId: number;
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }) =>
        `/command-center/v1/care-planning/hand-off/${handOffId}/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/not-available`,
    HAND_OFF_UNLOCK_NOT_AVAILABLE: ({
        handOffId,
        vendorCode,
        siteCode,
        actorCode,
    }: {
        handOffId: number;
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }) =>
        `/command-center/v1/care-planning/hand-off/${handOffId}/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/unlock`,
    HAND_OFF_COUNTERS: '/command-center/v1/care-planning/counters/handoff-request-counters',
    HAND_OFF_REQUEST_INCOMPLETE: (handOffId: number) =>
        `/command-center/v1/care-planning/hand-off/${handOffId}/request-incomplete` as const,
    HAND_OFF_STATUS: (handOffId: number) => `/command-center/v1/care-planning/hand-off/${handOffId}` as const,
    HAND_OFF_CANCEL: (handOffId: number) => `/command-center/v1/care-planning/hand-off/${handOffId}/cancel` as const,
    HAND_OFF_STATUSES: '/command-center/v1/care-planning/hand-off/statuses',
    HAND_OFF_URGENCIES: '/command-center/v1/care-planning/hand-off/urgencies',
    HAND_OFF_CANCELLATION_STATUSES: '/command-center/v1/care-planning/cancellation-service-requests/statuses',
    HAND_OFF_CANCELLATION_URGENCIES: '/command-center/v1/care-planning/cancellation-service-requests/urgencies',
    HAND_OFF_CANCEL_REASONS: '/command-center/v1/care-planning/hand-off/cancel-reasons',
    HAND_OFF_COUNTERS_SCHEDULING: '/command-center/v2/care-planning/counters/scheduling',
    HAND_OFF_COUNTERS_MODIFICATIONS: '/command-center/v2/care-planning/counters/modifications',
    HAND_OFF_COUNTERS_VENDOR_CHANGES: '/command-center/v2/care-planning/counters/vendor-changes',
    HAND_OFF_COUNTERS_CANCELLATIONS: '/command-center/v2/care-planning/counters/cancellations',
    HAND_OFF_COUNTERS_OUTSTANDING_DOCUMENTATION: '/command-center/v2/care-planning/counters/outstanding-documentation',
    HAND_OFF_CHANGE_PARTNER: '/command-center/v1/care-planning/hand-off/change-vendor-handoff',
    HAND_OFF_REASONS_NOT_AVAILABLE_PARTNER: '/command-center/v1/care-planning/hand-off/not-available-reasons',
    REASONS_CHANGE_PARTNER: '/command-center/v1/care-planning/hand-off/changing-partner-reasons',
    HAND_OFF_CHANGE_PARTNER_BY_ID: (id: number) => `/command-center/v1/change-partner-requests/${id}` as const,
    REQUIRED_ACTIVITY: (activityId: number) =>
        `/command-center/v1/care-planning/activities/${activityId}/required` as const,
    ACTIVITY_HISTORY: (activityId: number) =>
        `/command-center/v1/care-planning/activities/${activityId}/status-history` as const,
    UPDATE_ACTIVITY_DATE: (activityId: number) =>
        `/command-center/v1/care-planning/activities/${activityId}/reschedule` as const,
    SET_ACTIVITY_DATE: (activityId: number) =>
        `/command-center/v1/care-planning/activities/${activityId}/tbd/plan-on-date` as const,
    ACTIVITY_LABEL: (activityId: number) => `/command-center/v1/care-planning/activities/${activityId}/label` as const,
    MULTIPLE_DAYS_MULTIPLE_ACTIVITIES_ORDERS: (orderId: string) =>
        `/command-center/v1/care-planning/orders/${orderId}/multiple-days-related-by-date` as const,
    CREATE_MULTI_DAYS_MULTI_ACTIVITIES_APPOINTMENTS:
        '/command-center/v1/care-planning/appointments/in-home/multi-day-multiple-activities',
    APPOINTMENT_CALENDAR: '/command-center/v1/care-planning/appointment-calendar/',
    VENDORS_APPOINTMENT_CALENDAR: '/command-center/v2/care-planning/appointment-calendar/vendors',
    APPOINTMENT_CALENDAR_PATIENT_FILTER_ITEMS: '/command-center/v1/care-planning/appointment-calendar/filter-items',
    IN_HOME_APPOINTMENT_STATE_GROUPS: '/command-center/v1/care-planning/appointments/in-home/state-groups',
    NEW_ORDERS_COUNTER: '/command-center/v1/care-planning/orders/new/count',
    PRE_DOCUMENTATION_APPOINTMENTS_COUNTER:
        '/command-center/v1/care-planning/appointments/in-home/pre-documented/count',
    APPOINTMENT_SELF_CHECK_IN_REVIEW_DETAILS: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-in-review-details` as const,
    APPOINTMENT_SELF_CHECK_OUT_REVIEW_DETAILS: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-out-review-details` as const,
    ADD_SELF_CHECK_IN_ACTIVITIES: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/add-self-check-in-activities` as const,
    ADD_SELF_CHECK_OUT_ACTIVITIES: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/add-self-check-out-activities` as const,
    SAVE_REVIEW_CHECK_IN: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-in-confirm` as const,
    SAVE_REVIEW_CHECK_OUT: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-out-confirm` as const,
    VOID_REVIEW_CHECK_IN: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-in-void` as const,
    VOID_REVIEW_CHECK_OUT: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/self-check-out-void` as const,
    CUSTOM_ARRIVAL_WINDOW_DURATIONS: '/command-center/v1/care-planning/appointments/custom-arrival-window-durations',
    PERFORMANCE_IMPROVEMENT_LOG_APPOINTMENT_DETAILS: (appointmentId: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${appointmentId}/details` as const,
    MODIFICATIONS: '/command-center/v1/appointment-modification-request',
    MODIFICATION_DETAILS: (modificationId: number) =>
        `/command-center/v1/appointment-modification-request/modification-details/${modificationId}` as const,
    MODIFICATION_SCHEDULING_DETAILS: (modificationId: number) =>
        `/command-center/v1/appointment-modification-request/modification-details/${modificationId}/scheduling-details`,
    MODIFICATION_SERVICE_REQUEST: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}` as const,
    MODIFICATION_SERVICE_REQUEST_ON_START_WORKING: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/start-working` as const,
    VENDOR_MODIFICATION_SERVICE_REQUEST_IDS: (vendorCode: string) =>
        `/command-center/v1/appointment-modification-request/vendor/${vendorCode}` as const,
    MODIFICATION_SERVICE_REQUEST_VENDOR_STATUSES: '/command-center/v1/appointment-modification-request/vendor-statuses',
    MODIFICATION_SERVICE_REQUEST_REFUSE_REASONS: '/command-center/v1/appointments/modification/refuse-reasons',
    UPDATE_MODIFICATION_SERVICE_REQUEST_VENDOR_STATUS: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/vendor-status` as const,
    UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_SENT_DATE: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/referral-sent-date` as const,
    UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_ACCEPTED_DATE: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/referral-accepted-date` as const,
    UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_DATES: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/referral-dates` as const,
    COMPLETE_MODIFICATION_SERVICE_REQUEST: (requestId: number) =>
        `/command-center/v1/appointment-modification-request/${requestId}/complete` as const,
    ACTIVITY_REFUSE_REASONS: '/command-center/v1/instance-properties/activity-refuse-reasons' as const,
} as const;

const ONE_OFF_TASKS = {
    TASKS_TEMPLATES: '/command-center/v1/one-off-tasks/templates',
    ORDER_AUTO_CREATE: '/command-center/v1/one-off-tasks/templates/order-auto-create',
    TASKS_TEMPLATES_BY_ID: (id: number) => `/command-center/v1/one-off-tasks/templates/${id}` as const,
    TASKS_TEMPLATES_PATIENT: '/command-center/v1/one-off-tasks/templates/patient-relevant',
    TASKS_TEMPLATES_CATEGORIES: '/command-center/v1/one-off-tasks/templates/categories',
    STANDARTIZED_TASK: '/command-center/v1/one-off-tasks/standard-task',
    ONE_OFF_SIMPLE_TASK: '/command-center/v1/one-off-tasks/simple-task',
    TASKS: (patientId: number) => `/command-center/v1/one-off-tasks/${patientId}` as const,
    TASK_HISTORY: (patientId: number) => `/command-center/v1/one-off-tasks/${patientId}/history` as const,
    TASKS_TEMPLATES_VISIBILITY: (templateId: number) =>
        `/command-center/v1/one-off-tasks/templates/${templateId}/toggle` as const,
    STANDARTIZED_TASK_DETAILS: (taskId: number) => `/command-center/v1/one-off-tasks/standard-task/${taskId}` as const,
    STANDARTIZED_TASK_CANCEL: (taskId: number) =>
        `/command-center/v1/one-off-tasks/standard-task/${taskId}/cancel` as const,
    STANDARTIZED_TASK_FINISH: (taskId: number) =>
        `/command-center/v1/one-off-tasks/standard-task/${taskId}/finish` as const,
    STANDARTIZED_TASK_REOPEN: (taskId: number) =>
        `/command-center/v1/one-off-tasks/standard-task/${taskId}/reopen` as const,
    TASKS_COUNT: (patientId: number) => `/command-center/v1/one-off-tasks/${patientId}/count` as const,
} as const;

const SUPPLY_CHAIN = {
    MAKE_NOT_AVAILABLE: ({
        vendorCode,
        siteCode,
        actorCode,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/not-available` as const,
    VENDOR_REASONS_NOT_AVAILABLE_PARTNER: '/command-center/v1/supplychain/vendors/not-available-reasons/',
    ELIGIBLE_ACTORS: '/command-center/v1/supplychain/vendors/patient_eligible_actors',
    VENDOR_DICTIONARY_TREE: '/command-center/v1/supplychain/vendors/vendors-tree',
    VENDORS: '/command-center/v1/supplychain/vendors',
    VENDOR: (vendorCode: string) => `/command-center/v1/supplychain/vendors/${vendorCode}` as const,
    SITES: (vendorCode: string) => `/command-center/v1/supplychain/vendors/${vendorCode}/sites` as const,
    SITE: (vendorCode: string, siteCode: string) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}` as const,
    GET_AVAILABLE_VENDOR_SITE_OPTIONS: '/command-center/v1/supplychain/vendors/available-sites',
    ACTORS: (vendorCode: string, siteCode: string) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors` as const,
    ACTOR: (vendorCode: string, siteCode: string, actorCode: string) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}` as const,
    ACTOR_IS_EVER_USED: ({
        vendorCode,
        siteCode,
        actorCode,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }) => `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/is-ever-used`,
    ACTOR_HAS_ACTIVE_APPOINTMENT: (assignmentGroupId: string) =>
        `/command-center/v1/supplychain/vendors/isAssigned/${encodeURIComponent(assignmentGroupId)}`,
    ACTIVITIES: '/command-center/v1/care-planning/order-specifications/activities',
    ACTIVITY: (vendorCode: string, siteCode: string, actorCode: string, activityCode: string) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/activities/${activityCode}` as const,
    ACTIVITY_CREATE: (vendorCode: string, siteCode: string, actorCode: string) =>
        `/command-center/v1/supplychain/vendors/${vendorCode}/sites/${siteCode}/actors/${actorCode}/activities` as const,
    SELECTED_VENDOR: '/command-center/v1/supplychain/vendors/select-vendor',
    CONTACT_TYPES_GET: '/command-center/v1/supplychain/vendors/contact-types',
    EXPORT_ACTOR_ACTIVITY: '/command-center/v1/supplychain/vendors/export-actor-activity',
    APPOINTMENT_ELIGIBLE_ACTORS: (appointmentId: number) =>
        `/command-center/v1/supplychain/vendors/appointment_eligible_actors?appointmentId=${appointmentId}` as const,
    SERVICE_AREA_GET_ALL: '/command-center/v1/supplychain/service-area/all',
    SERVICE_AREA_CREATE_TOWNS: '/command-center/v1/supplychain/service-area/towns',
    SERVICE_AREA_BY_ID_TOWNS: (id: number) => `/command-center/v1/supplychain/service-area/towns/${id}` as const,
    SERVICE_AREA_CREATE_ZIPS: '/command-center/v1/supplychain/service-area/zips',
    SERVICE_AREA_BY_ID_ZIPS: (id: number) => `/command-center/v1/supplychain/service-area/zips/${id}` as const,
    CREATE_GRAND_SERVICE_AREA: '/command-center/v1/supplychain/service-area/grand',
    GRAND_SERVICE_AREA_BY_ID: (id: number) => `/command-center/v1/supplychain/service-area/grand/${id}` as const,
    SELF_CHECK_IN_OPTIONS: '/command-center/v1/supplychain/vendors/self-check-in-options',
    SELF_CHECK_OUT_OPTIONS: '/command-center/v1/supplychain/vendors/self-check-out-options',
} as const;

const ALARM = {
    ALARMS: '/command-center/v1/alarm',
} as const;

const SHIFTS = {
    SHIFTS: '/command-center/v1/mission-control/shift' as const,
    END_SHIFT: (id: number) => `/command-center/v1/mission-control/shift/${id}/end` as const,
};

const STATISTICS = {
    ACTIVE_AGENTS: '/telephony/v1/call-center/active-agent-list' as const,
};

const FEATURES = {
    FEATURES: '/command-center/v1/features',
    FEATURES_CESIA_MANAGED: '/command-center/v1/features/cesia-managed',
    FEATURE_ENABLE: (featureUid: string) => `/command-center/v1/features/${featureUid}/enable` as const,
    FEATURE_DISABLE: (featureUid: string) => `/command-center/v1/features/${featureUid}/disable` as const,
} as const;

const CONFIGURATION = {
    INSTANCE_INFO: '/command-center/v1/configuration/INSTANCE_INFO',
} as const;

const EDUCATION_DOCUMENTS = {
    EDUCATION_DOCUMENTS_TYPES: '/command-center/v1/education/documents/types',
    EDUCATION_DOCUMENTS: '/command-center/v2/education/documents',
    EDUCATION_DOCUMENT: (id: number) => `/command-center/v2/education/documents/${id}` as const,
    EDUCATION_DOCUMENT_FILE: '/command-center/v1/education/documents/file/history',
    EDUCATION_DOCUMENT_CHANGE_ORDER: (id: number) =>
        `/command-center/v1/education/documents/${id}/change-order` as const,
    EDUCATION_DOCUMENT_ARCHIVE: (id: number) => `/command-center/v1/education/documents/${id}/archive` as const,
    EDUCATION_DOCUMENT_RESTORE: (id: number) => `/command-center/v1/education/documents/${id}/restore` as const,
    EDUCATION_DOCUMENT_HISTORY: (id: number) => `/command-center/v2/education/documents/${id}/history` as const,
} as const;

const APPOINTMENT_DOCUMENTATION = {
    APPOINTMENT_DOCUMENTATION_SITES: '/command-center/v1/care-planning/appointments/in-home/sites-for-documentation',
    APPOINTMENT_DOCUMENTATION_PATIENTS: '/command-center/v1/mission-control/patients/all-names',
    APPOINTMENT_DOCUMENTATION_REQUESTS: '/command-center/v1/care-planning/appointments/in-home/pre-documented',
    APPOINTMENT_DOCUMENTED_BY_PATIENT:
        '/command-center/v1/care-planning/appointments/in-home/pre-documented-by-patient',
} as const;

const PATIENTS = {
    PATIENT_ASSIGNMENTS: '/command-center/v1/mission-control/patient-assignments/patients',
    PATIENT_ASSIGNMENTS_HISTORY: (patientId: number) =>
        `/command-center/v1/mission-control/patient-assignments/${patientId}/assignment-history` as const,
    PATIENT_AVAILABLE_ASSIGNMENTS: '/command-center/v1/mission-control/patient-assignments/available-assignments',
    SINGLE_PATIENT_ASSIGNMENTS: '/command-center/v1/mission-control/patient-assignments/patient-available-assignments',
    MULTI_SHIFT_ASSIGNMENT: '/command-center/v2/shift-assignments/multiple',
} as const;

const MY_PAGE = {
    MY_PAGE_PATIENTS: '/command-center/v1/my-page/patients',
    MY_PAGE_PATIENTS_COUNT: '/command-center/v1/my-page/patients/count',
    MY_PAGE_AGENDA_COUNT: '/command-center/v1/my-page/appointments/count',
    MY_PAGE_APPOINTMENTS_AGENDA: '/command-center/v1/my-page/appointments',
    AGENDA_APPOINTMENT_IN_HOME_OVERVIEW: (id: number) =>
        `/command-center/v1/care-planning/appointments/in-home/${id}/agenda-details` as const,
    WIDGET_OUTSTANDING_DOCUMENTS_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/care-planning/widget/appointments/in-home/pre-documented/personnel/${id}` as const,
    WIDGET_OUTSTANDING_DOCUMENTS_BY_PATIENT: (id: number) =>
        `/command-center/v1/care-planning/widget/appointments/in-home/pre-documented/patient/${id}` as const,
    WIDGET_TASKS_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/care-planning/widget/one-off-tasks/task/personnel/${id}` as const,
    WIDGET_TASKS_BY_PATIENT: (id: number) =>
        `/command-center/v1/care-planning/widget/one-off-tasks/task/patient/${id}` as const,
    WIDGET_EMPTY_APPOINTMENTS_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/care-planning/widget/appointments/in-home/empty/personnel/${id}` as const,
    WIDGET_EMPTY_APPOINTMENTS_BY_PATIENT: (id: number) =>
        `/command-center/v1/care-planning/widget/appointments/in-home/empty/patient/${id}` as const,
    WIDGET_SUMMARY_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/care-planning/widget/counters/personnel/${id}` as const,
    WIDGET_SUMMARY_BY_PATIENT: (id: number) =>
        `/command-center/v1/care-planning/widget/counters/patient/${id}` as const,
    WIDGET_SERVICE_REQUESTS_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/care-planning/widget/service-request/personnel/${id}` as const,
    WIDGET_SERVICE_REQUESTS_BY_PATIENT: (id: number) =>
        `/command-center/v1/care-planning/widget/service-request/patient/${id}` as const,
    WIDGET_ACTIVITIES_BY_PERSONNEL: (id: number) =>
        `/command-center/v1/widget/orders/activity/personnel/${id}` as const,
    WIDGET_ACTIVITIES_BY_PATIENT: (id: number) => `/command-center/v1/widget/orders/activity/patient/${id}` as const,
    WIDGET_CARE_PLANNING_BY_PATIENT: (id: number) => `/command-center/v1/care-planning/widget/details/${id}` as const,
    WIDGET_CARE_PLANNING_BY_PERSONNEL: '/command-center/v1/care-planning/widget/details',
} as const;

const ROUTING = {
    ROUTING_PROFILES: '/telephony/v1/routing/routing-profiles',
};

const SPI = {
    SEND_SPI: '/command-center/v1/spi/hand-off-vendor/send',
    SEND_APPOINTMENT_REQUEST: '/command-center/v1/spi/hand-off-vendor/send-appointment-request',
    APPOINTMENT_VISIT_TYPES: '/command-center/v1/spi/appointment-visit-types',
    LAST_APPOINTMENT_REQUEST: (handOffId: number) =>
        `/command-center/v1/spi/hand-off-vendor/${handOffId}/last-appointment-request`,
    APPOINTMENT_REQUEST_DETAILS: (handOffId: number) =>
        `/command-center/v1/spi/hand-off-vendor/${handOffId}/appointment-request-details`,
} as const;

const APPOINTMENTS = {
    APPOINTMENT_POTENTIAL_ISSUE: (appointmentId: number) =>
        `/command-center/v1/appointments/in-home/potential-issues/${appointmentId}` as const,
} as const;

const MODIFICATION_REQUEST = {
    POSTPONED_NOTIFICATION: (notificationId: string) =>
        `/command-center/v1/postponed-notification/${notificationId}` as const,
} as const;

const BARCODES = {
    GET_BARCODE: '/command-center/v1/barcodes',
    DELETE_BARCODE: (id: number) => `/command-center/v1/barcodes/${id}`,
    SEND_BARCODE_ISSUE: '/command-center/v1/barcode-reports',
} as const;

export default {
    ...SHIFTS,
    ...MISSION_CONTROL,
    ...INVENTORY,
    ...CARE_PLANNING,
    ...ONE_OFF_TASKS,
    ...SUPPLY_CHAIN,
    ...ALARM,
    ...STATISTICS,
    ...CONFIGURATION,
    ...EDUCATION_DOCUMENTS,
    ...FEATURES,
    ...APPOINTMENT_DOCUMENTATION,
    ...PATIENTS,
    ...MY_PAGE,
    ...ROUTING,
    ...SPI,
    ...APPOINTMENTS,
    ...MODIFICATION_REQUEST,
    ...BARCODES,
};
